import { Address } from '@features/profile/profile.types';

/**
 * convertAddressToSingleLine
 *
 * This function takes an object shaped like an Address and converts it to a single line string.
 */
export const convertAddressToSingleLine = (address: Address): string => {
  const addressParts = [
    address.addressLine1,
    address.city,
    address.county,
    address.postcode,
    address.country,
  ];

  return addressParts
    .filter(addressLine => addressLine && addressLine.trim().length > 0)
    .join(', ');
};
