import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { meReducer } from '@features/profile/profile';

const rootReducer = combineReducers({
  me: meReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type InitialState = Partial<RootState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
