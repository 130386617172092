export const environmentFor = (href: string) => {
  const hostname = new URL(href).hostname;
  const m = hostname.match(/(\w+)\.home.cc/);
  if (m) {
    switch (m[1]) {
      case 'my':
        return 'production';
      case 'staging':
        return 'staging';
      default:
        return 'dev';
    }
  }
  return 'local';
};
