import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppThunkDispatch } from 'src/App';

import { FloatingNav } from '@components/FloatingNav';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { PageLoading } from '@components/PageLoading';

import { selectProfile } from '@features/profile/profile';
import { fetchMyProfile } from '@features/profile/profile.thunks';

import { Error } from '@templates/Error';
import { Holding } from '@templates/Holding';

import { checkAuthentication } from '@utils/checkAuthentication';
import { convertAddressToSingleLine } from '@utils/convertAddress';

interface AuthenticatedProps {
  title?: string;
  component?: React.ReactNode;
}

export const Authenticated = ({ component, title }: AuthenticatedProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const dispatch = useDispatch<AppThunkDispatch>();

  const navigate = useNavigate();

  const profile = useSelector(selectProfile);

  const address = profile.value?.properties?.[0]?.addressLine1 ?? '';
  const postcode = profile.value?.properties?.[0]?.postcode ?? '';
  const moveStatus = profile.value?.properties?.[0]?.moveStatus ?? '';

  const shouldShowFloatingNav = !!postcode && !!address && moveStatus !== 'COMPLETED';

  const allFetchesComplete = profile.fetchComplete;

  // Check if user has authentication via api/auth/check
  useEffect(() => {
    const authenticate = async () => {
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        dispatch(fetchMyProfile());
        setIsAuthenticated(true);
      } else {
        navigate('/signin');
      }
    };

    authenticate();
  }, []); // eslint-disable-line

  // Show Error template if anything went wrong fetching the users data
  if (profile.errorOccurred)
    return <Error title="Something went wrong" text="Reloading may fix this" />;

  // Show Page loading until all data has been fetched
  if (!allFetchesComplete) return <PageLoading />;

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{title || 'Home'}</title>
      </Helmet>
      <div className="flex flex-col">
        <Header loggedIn={isAuthenticated} hideNavigation />
        {shouldShowFloatingNav && <FloatingNav />}
        {component}
        <Footer isMoving={false} hideNavigation />
      </div>
    </>
  );
};
