import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuid } from 'uuid';

import { checkIfSentryShouldInit } from './checkIfSentryShouldInit';
import { environmentFor } from './environment';

const ENVIRONMENT = environmentFor(window.location.href);

const initSentry = checkIfSentryShouldInit(ENVIRONMENT);

const customAxios: AxiosInstance = axios.create({
  timeout: 35000,
});

axiosRetry(customAxios, { retries: 1 });

customAxios.interceptors.request.use(
  config => {
    config.headers['x-b3-traceid'] = uuid();

    return config;
  },
  error => Promise.reject(error)
);

customAxios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      error.response?.status === 401 &&
      !error.response?.config.url?.includes('/home/auth/signin')
    ) {
      window.location.href = '/signin'; // For now redirect to signin page when an endpoint returns unauthorized. This will be changed to refresh token in the future etc.
    }

    if (initSentry) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx

        // Don't want to report permissions failures - these are expected
        if (![401, 403].includes(error.response.status)) {
          Sentry.captureException(error.response.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        Sentry.captureException(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        Sentry.captureException(error.message);
      }
    }
    return Promise.reject(error);
  }
);

export default customAxios;
