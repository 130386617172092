/**
 * Catching "ResizeObserver loop limit exceeded" error, which is caused by Material UI or Homebox
 */
export const handleResizeObserverError = () => {
  window.addEventListener('error', event => {
    if (event.message.includes('ResizeObserver loop limit exceeded')) {
      event.preventDefault();

      console.warn('ResizeObserver loop limit exceeded error suppressed.');

      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');

      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }

      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });
};
