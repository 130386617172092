const roundDownToFixed = (value: number, digits: number) => {
  const factor = Math.pow(10, digits);
  return Math.floor(value * factor) / factor;
};

export const formatSpeed = (value: number) => {
  const Mbps = value / 1_000;
  return {
    speed: Mbps >= 1000 ? roundDownToFixed(Mbps / 1000, 2) : roundDownToFixed(Mbps, 0),
    unit: Mbps >= 1000 ? 'Gbps' : 'Mbps',
  };
};
