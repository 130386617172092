export const generateHoneycombKey = (href: string): string => {
  const hostname = new URL(href).hostname;
  const m = hostname.match(/(\w+)\.home.cc/);
  if (m) {
    switch (m[1]) {
      case 'my':
        return 'hcaik_01htz4ypv17b022hg8qakqxfsfqr7m3v6j6r706yqrjstxy7vwm2b3csy9';
      case 'staging':
        return 'hcaik_01htz4x0ctfs5rc7bnzcx334p82bkqk8j9q1hg965d3ahr0baexv5wg75c';
      default:
        return 'hcaik_01htz1ya7ym5an3r3t62cbbb155csfx9k96z4bj0majradty0d9ddkza5r';
    }
  }

  //   return 'hcaik_01ht045t58wwkyp99zj3fqy299spj18et2tah9d3t99jv99fs14taepf41'; // Enable local

  return '';
};
